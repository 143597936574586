<template>
  <!--
    Lists available cameras and links to camera-edit-pages etc.
  -->
  <Portlet
    :title="$t('cameraManagement')"
    icon="camera"
    class="cameraManagement"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getAllCameras()"
      />
      <div class="btn-group btn-group-sm ml-2">
        <router-link
          :to="streamCheckUrl"
          class="btn btn-sm btn-secondary"
        >
          <font-awesome-icon
            class="gray mr-1"
            icon="film"
          />
          <span>
            {{ $t('cameraTesterComponent.startTest') }}
          </span>
        </router-link>
        <router-link
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          :to="configUrl"
          class="btn btn-sm btn-secondary"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="wrench"
          />
          <span>{{ $t('configuration') }}</span>
        </router-link>
        <button
          class="btn btn-sm btn-secondary"
          @click="openConfirmModal()"
        >
          <font-awesome-icon
            class="gray mr-2"
            icon="redo-alt"
          /><span>Restart</span>
        </button>
      </div>
    </template>

    <LoadingPlaceholder v-if="loadingPlaceholder" />
    <template v-else>
      <table
        v-if="cameras"
        class="defaultTable"
      >
        <colgroup>
          <col width="50px">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('type') }}</th>
            <th>{{ 'PT Head' }}</th>
            <th>{{ $t('status') }}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in cameras"
            :key="`cameraManagement-cameraTableRow-${ index }`"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.cameraType }}</td>
            <td>{{ item.panTiltConfig ? item.panTiltConfig.name : "" }}</td>
            <td>
              <CameraOperationStatusText :status="item.cameraStatus" />
            </td>
            <td>
              <button
                class="btn btn-sm btn-secondary btn-block"
                @click="enableSidebar(item.id)"
              >
                <font-awesome-icon
                  class="gray mr-1"
                  icon="info-circle"
                />
                <span>
                  {{ $t('details') }}
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>

      <Sidebar
        v-if="cameras && currentId"
        :show-sidebar="showSidebar"
        :sidebar-width="600"
        @close="disableSidebar"
      >
        <CameraManagementDetail
          :installation-id="installationId"
          :camera-id="currentId"
          :lane-number="laneNumber"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'CameraManagement'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartCameraManagement()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'CameraManagement',
  components: {
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    CameraOperationStatusText: () => import('@/components/VideoDirector/CameraOperationStatusText.vue'),
    CameraManagementDetail: () => import('@/components/CameraManagement/CameraManagementDetail.vue'),
    SweetModal
  },
  mixins: [
    dateTimeMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      loadingPlaceholder: true,
      cameras: null,
      showSidebar: false,
      currentId: null,
      cancelDataSource: null,
      dataInterval: null
    }
  },
  computed: {
    configUrl: function () {
      if (!this.laneNumber) 
      {
        return { path: `/installation/${ this.installationId }/tracker/config/cameramanagement` };
      }
      else //its a Dualslalom installation with own routing
      {
        return { path: `/installation/${ this.installationId }/tracker/config/cameramanagement/lane/${ this.laneNumber }` };
      }
    },
    streamCheckUrl: function () {
      if (!this.laneNumber) 
      {
        return { path: `/installation/${ this.installationId }/tracker/cameramanagement` };
      }
      else //its a Dualslalom installation with own routing
      {
        return { path: `/installation/${ this.installationId }/tracker/cameramanagement/lane/${ this.laneNumber }` };
      }
    }
  },
  created () {
    this.createDataInterval();
  },
  beforeDestroy () {
    this.clearDataInterval();
    this.cancelDataRequest();
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    enableSidebar (id) {
      this.currentId = id;
      this.showSidebar = true;
    },
    disableSidebar () {
      this.currentId = null;
      this.showSidebar = false;
    },
    cancelDataRequest () {
      if (this.cancelDataSource) {
        this.cancelDataSource.cancel();
      }
    },
    createDataInterval () {
      this.getAllCameras();
      this.dataInterval = window.setInterval(this.getAllCameras, 1000);
    },
    clearDataInterval () {
      window.clearInterval(this.dataInterval);
      this.dataInterval = null;
    },
    getAllCameras () {
      this.loading = true;
      this.cancelDataRequest();
      this.cancelDataSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelDataSource.token };
      let url = `/CameraManagement/GetAllCameras?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.cameras = response.data;
        })
        .finally(() => {
          this.loadingPlaceholder = false;
          this.loading = false;
        });
    },
    restartCameraManagement () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=CameraManagement&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartCameraManagement',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart CameraManagement");
                });
            }
        }
        });
    }
  }
}
</script>

<style scoped>
.sidebar img {
  width: 100%;
  height: auto;
}
</style>

<style>
.cameraManagement .m-portlet__body {
  min-height: 105px !important;
}
</style>
